
import store from "@/store";

// 提示语 nonGK:非本年高考 scoreGK:填写成绩提示
function message(type, el) {
    el.style.color = 'var(--el-color-error)';
    el.style.marginBottom = '3px';
    el.style.fontWeight = 'bold';
    el.textContent = type == 'nonYearGK' ? '高考期间非高三考生暂时无法使用该功能！' : type == 'isGK' ? '高考期间只能填写一次正确成绩，请谨慎填写！' : '';
}

// 操作
function domHandle(type, el, domType, status) {
    if (type == 'disabled') {
        if (domType == 'div') {
            const styleData = {
                cursor: status ? 'not-allowed' : '',
                backgroundColor: status ? 'var(--el-color-primary-light-5)' : 'var(--el-color-primary)',
                borderColor: status ? 'var(--el-color-primary-light-9)' : 'var(--el-color-primary)',
                position: status ? 'static' : 'relative',
                opacity: status ? '1' : ''
            }

            Object.keys(styleData).forEach(key => el.style[key] = styleData[key])

            el.classList[status ? 'remove' : 'add']('btnActive')
            el.children[0] && (el.children[0].style.display = status ? 'none' : '')
        } else {
            // 实现按钮禁用
            el.disabled = status
            // 增加 elementUI 的禁用样式类
            status ? el.classList.add('is-disabled') : el.classList.remove('is-disabled')
        }
    }
}

function init(el, binding) {
    const { value } = binding; // 节点参数
    if (typeof value == 'object') {
        const gaokao_type = store.getters?.is_userGaokao

        if (value.message) {
            // 提示语
            message(gaokao_type, el)
        } else {
            // 操作
            const edit_score_num = store.state?.userInfo?.info?.remaining_score_edit_count; // 修改成绩次数
            if (value.type == 'disabled' && (gaokao_type == 'nonYearGK' || (gaokao_type == 'isGK' && !edit_score_num && value?.gaokao !== 'open'))) {
                // 禁止 domType元素类型
                domHandle(value.type, el, value?.domType, true)
            } else {
                // 放开 domType元素类型
                domHandle(value.type, el, value?.domType, false)
            }
        }
    }
}

export default {
    mounted: (el, binding) => init(el, binding),
    updated: (el, binding) => init(el, binding)
}