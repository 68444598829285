import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    meta: {
      title: '首页',
      label: ''
    },
    component: function () {
      return import('../views/layout/index.vue')
    },
    redirect: '/home',
    children: [
      {
        path: '/home',
        name: '/home',
        meta: {
          title: '',
          label: ''
        },
        component: function () {
          return import('../views/home.vue')
        },
      },

      /**
       * @高考资讯
       */
      {
        path: '/information',
        name: '/information',
        meta: {
          title: '高考资讯',
          label: '高考资讯'
        },
        component: function () {
          return import('@/views/subviews/information/information.vue')
        },
        redirect: '/information/list',
        children: [
          {
            path: '/information/list',
            name: '/information/list',
            meta: {
              title: '',
              label: '高考资讯'
            },
            component: function () {
              return import('@/views/subviews/information/information_list.vue')
            },
          },
          {
            path: '/information/detail',
            name: '/information/detail',
            meta: {
              title: '资讯详情',
              label: '资讯详情'
            },
            component: function () {
              return import('@/views/subviews/information/information_detail.vue')
            },
          },
        ]
      },

      /**
       * @智能选科
       */
      {
        path: '/subjectSelection',
        name: '/subjectSelection',
        meta: {
          title: '智能选科',
          label: '智能选科'
        },
        component: function () {
          return import('@/views/subviews/subjectSelection/subjectSelection.vue')
        },
        redirect: '/subjectSelection/index',
        children: [
          {
            path: '/subjectSelection/index',
            name: '/subjectSelection/index',
            meta: {
              title: '',
              label: '智能选科',
              is_login: true
            },
            component: function () {
              return import('@/views/subviews/subjectSelection/subjectSelection_index.vue')
            },
          },
          {
            path: '/subjectSelection/intelligent',
            name: '/subjectSelection/intelligent',
            meta: {
              title: '智能选科结果',
              label: '智能选科结果',
              is_login: true
            },
            component: function () {
              return import('@/views/subviews/subjectSelection/subjectSelection_intelligent.vue')
            },
          },
          {
            path: '/subjectSelection/autonomy',
            name: '/subjectSelection/autonomy',
            meta: {
              title: '自主分析结果',
              label: '自主分析结果',
              is_login: true
            },
            component: function () {
              return import('@/views/subviews/subjectSelection/subjectSelection_autonomy.vue')
            },
          },
          {
            path: '/subjectSelection/query',
            name: '/subjectSelection/query',
            meta: {
              title: '选考科目查询',
              label: '选考科目查询',
              is_login: true
            },
            component: function () {
              return import('@/views/subviews/subjectSelection/subjectSelection_query.vue')
            },
          },
        ]
      },

      /**
       * @填报志愿
       */
      {
        path: '/fillCollege',
        name: '/fillCollege',
        meta: {
          title: '填报志愿',
          label: '填报志愿'
        },
        component: function () {
          return import('@/views/subviews/fillCollege/fillCollege.vue')
        },
        redirect: '/fillCollege/index',
        children: [
          {
            path: '/fillCollege/index',
            name: '/fillCollege/index',
            meta: {
              title: '',
              label: '智能模拟填报, 为您推荐合理方案',
              is_login: true
            },
            component: function () {
              return import('@/views/subviews/fillCollege/fillCollege_index.vue')
            },
          },
          {
            path: '/fillCollege/list',
            name: '/fillCollege/list',
            meta: {
              title: '',
              label: '智能模拟填报, 填高考分数推荐学校',
              is_login: true
            },
            component: function () {
              return import('@/views/subviews/fillCollege/fillCollege_list.vue')
            },
          },
          {
            path: '/fillCollege/result',
            name: '/fillCollege/result',
            meta: {
              title: '',
              label: '志愿排序',
              is_login: true
            },
            component: function () {
              return import('@/views/subviews/fillCollege/fillCollege_result.vue')
            },
          },
          {
            path: '/fillCollege/report',
            name: '/fillCollege/report',
            meta: {
              title: '',
              label: '志愿报告',
              is_login: true
            },
            component: function () {
              return import('@/views/subviews/fillCollege/fillCollege_report.vue')
            },
          }
        ]
      },

      /**
       * @数据查询
       */
      {
        path: '/dataQuery',
        name: '/dataQuery',
        meta: {
          title: '数据查询',
          label: '数据查询'
        },
        component: function () {
          return import('@/views/subviews/dataQuery/dataQuery.vue')
        },
        redirect: '/dataQuery/school',
        children: [
          /**
           * @院校库
           */
          {
            path: '/dataQuery/school',
            name: '/dataQuery/school',
            meta: {
              title: '',
              label: '数据查询-院校库'
            },
            component: function () {
              return import('@/views/subviews/dataQuery/dataQuery_school/dataQuery_school.vue')
            },
          },
          {
            path: '/dataQuery/school/contrast',
            name: '/dataQuery/school/contrast',
            meta: {
              title: '',
              label: '院校库-大学对比'
            },
            component: function () {
              return import('@/views/subviews/dataQuery/dataQuery_school/dataQuery_school_contrast.vue')
            },
          },
          {
            path: '/dataQuery/school/school_link',
            name: '/dataQuery/school/school_link',
            meta: {
              title: '',
              label: '院校VR'
            },
            component: function () {
              return import('@/views/subviews/dataQuery/dataQuery_school/dataQuery_school_link.vue')
            },
          },
          {
            path: '/dataQuery/school/detail',
            name: '/dataQuery/school/detail',
            meta: {
              title: '',
              label: '院校概况'
            },
            component: function () {
              return import('@/views/subviews/dataQuery/dataQuery_school/dataQuery_school_detail.vue')
            },
            redirect: '/dataQuery/school/detail_index',
            children: [
              {
                path: '/dataQuery/school/detail_index',
                name: '/dataQuery/school/detail_index',
                meta: {
                  title: '',
                  label: '院校概况',
                  keepAlive: true
                },
                component: function () {
                  return import('@/views/subviews/dataQuery/dataQuery_school/detail/detail_index')
                },
              },
              {
                path: '/dataQuery/school/detail_introduction',
                name: '/dataQuery/school/detail_introduction',
                meta: {
                  title: '',
                  label: '院校介绍',
                  keepAlive: true
                },
                component: function () {
                  return import('@/views/subviews/dataQuery/dataQuery_school/detail/detail_introduction')
                },
              },
              {
                path: '/dataQuery/school/detail_enrollmentGuide',
                name: '/dataQuery/school/detail_enrollmentGuide',
                meta: {
                  title: '',
                  label: '院校招生简章'
                },
                component: function () {
                  return import('@/views/subviews/dataQuery/dataQuery_school/detail/detail_enrollmentGuide')
                },
              },
              {
                path: '/dataQuery/school/detail_analysis',
                name: '/dataQuery/school/detail_analysis',
                meta: {
                  title: '',
                  label: '院校招生章程要点解析'
                },
                component: function () {
                  return import('@/views/subviews/dataQuery/dataQuery_school/detail/detail_analysis')
                },
              },
              {
                path: '/dataQuery/school/detail_enrollmentGuide/detail',
                name: '/dataQuery/school/detail_enrollmentGuide/detail',
                meta: {
                  title: '',
                  label: '院校招生简章详情'
                },
                component: function () {
                  return import('@/views/subviews/dataQuery/dataQuery_school/detail/detail_enrollmentGuide_detail')
                },
              },
              {
                path: '/dataQuery/school/detail_enrollmentPlan',
                name: '/dataQuery/school/detail_enrollmentPlan',
                meta: {
                  title: '',
                  label: '院校招生计划'
                },
                component: function () {
                  return import('@/views/subviews/dataQuery/dataQuery_school/detail/detail_enrollmentPlan')
                },
              },
              {
                path: '/dataQuery/school/detail_matriculateLine',
                name: '/dataQuery/school/detail_matriculateLine',
                meta: {
                  title: '',
                  label: '院校录取分数线'
                },
                component: function () {
                  return import('@/views/subviews/dataQuery/dataQuery_school/detail/detail_matriculateLine')
                },
              },
              {
                path: '/dataQuery/school/detail_mien',
                name: '/dataQuery/school/detail_mien',
                meta: {
                  title: '',
                  label: '院校风采'
                },
                component: function () {
                  return import('@/views/subviews/dataQuery/dataQuery_school/detail/detail_mien')
                },
              },
              {
                path: '/dataQuery/school/detail_foreseePlan',
                name: '/dataQuery/school/detail_foreseePlan',
                meta: {
                  title: '',
                  label: '提前批招生计划'
                },
                component: function () {
                  return import('@/views/subviews/dataQuery/dataQuery_school/detail/detail_foreseePlan')
                },
              },
              {
                path: '/dataQuery/school/detail_foreseeLine',
                name: '/dataQuery/school/detail_foreseeLine',
                meta: {
                  title: '',
                  label: '提前批分数线'
                },
                component: function () {
                  return import('@/views/subviews/dataQuery/dataQuery_school/detail/detail_foreseeLine')
                },
              }
            ]
          },

          /**
           * @专业库
           */
          {
            path: '/dataQuery/major',
            name: '/dataQuery/major',
            meta: {
              title: '',
              label: '数据查询-专业库'
            },
            component: function () {
              return import('@/views/subviews/dataQuery/dataQuery_major/dataQuery_major.vue')
            },
          },
          {
            path: '/dataQuery/major/rank',
            name: '/dataQuery/major/rank',
            meta: {
              title: '',
              label: '数据查询-专业排名'
            },
            component: function () {
              return import('@/views/subviews/dataQuery/dataQuery_major/dataQuery_major_rank.vue')
            },
          },
          {
            path: '/dataQuery/major/detail',
            name: '/dataQuery/major/detail',
            meta: {
              title: '',
              label: '数据查询-专业详情'
            },
            component: function () {
              return import('@/views/subviews/dataQuery/dataQuery_major/dataQuery_major_detail.vue')
            },
            redirect: '/dataQuery/major/detail_first',
            children: [
              {
                path: '/dataQuery/major/detail_first',
                name: '/dataQuery/major/detail_first',
                meta: {
                  title: '',
                  label: '数据查询-专业概况,就业前景,开设院校'
                },
                component: function () {
                  return import('@/views/subviews/dataQuery/dataQuery_major/detail/detail_first.vue')
                },
              },
              {
                path: '/dataQuery/major/detail_second',
                name: '/dataQuery/major/detail_second',
                meta: {
                  title: '',
                  label: '数据查询-专业概况,就业前景,开设院校'
                },
                component: function () {
                  return import('@/views/subviews/dataQuery/dataQuery_major/detail/detail_second.vue')
                },
              }
            ]
          },

          /**
           * @职业库
           */
          {
            path: '/dataQuery/job',
            name: '/dataQuery/job',
            meta: {
              title: '',
              label: '职业库'
            },
            component: function () {
              return import('@/views/subviews/dataQuery/dataQuery_job/dataQuery_job.vue')
            },
          },
          {
            path: '/dataQuery/job/detail',
            name: '/dataQuery/job/detail',
            meta: {
              title: '',
              label: '职业库-职业'
            },
            component: function () {
              return import('@/views/subviews/dataQuery/dataQuery_job/dataQuery_job_detail.vue')
            },
            redirect: '/dataQuery/job/detailClass',
            children: [
              {
                path: '/dataQuery/job/detailClass',
                name: '/dataQuery/job/detailClass',
                meta: {
                  title: '',
                  label: '职业概况、就业岗位'
                },
                component: function () {
                  return import('@/views/subviews/dataQuery/dataQuery_job/detail/dataQuery_job_detailClass.vue')
                },
              },
              {
                path: '/dataQuery/job/detailProfession',
                name: '/dataQuery/job/detailProfession',
                meta: {
                  title: '',
                  label: '职业概况、就业岗位'
                },
                component: function () {
                  return import('@/views/subviews/dataQuery/dataQuery_job/detail/dataQuery_job_detailProfession.vue')
                },
              },
            ]
          },

          /**
           * @提前批
           */
          {
            path: '/dataQuery/foresee',
            name: '/dataQuery/foresee',
            meta: {
              title: '',
              label: '数据查询-提前批'
            },
            component: function () {
              return import('@/views/subviews/dataQuery/dataQuery_foresee/dataQuery_foresee.vue')
            },
          },

          /**
           * @位次查询
           */
          {
            path: '/dataQuery/rank',
            name: '/dataQuery/rank',
            meta: {
              title: '',
              label: '数据查询-位次查询'
            },
            component: function () {
              return import('@/views/subviews/dataQuery/dataQuery_rank/dataQuery_rank.vue')
            },
          },

          /**
           * @分数线
           */
          {
            path: '/dataQuery/score',
            name: '/dataQuery/score',
            meta: {
              title: '',
              label: '数据查询-分数线'
            },
            component: function () {
              return import('@/views/subviews/dataQuery/dataQuery_score/dataQuery_score.vue')
            },
          },

          /**
           * @批次线
           */
          {
            path: '/dataQuery/batch',
            name: '/dataQuery/batch',
            meta: {
              title: '',
              label: '数据查询-批次线'
            },
            component: function () {
              return import('@/views/subviews/dataQuery/dataQuery_batch/dataQuery_batch.vue')
            },
          }
        ]
      },


      /**
       * @学业测评
       */
      {
        path: '/evaluation',
        name: '/evaluation',
        meta: {
          title: '',
          label: '测评'
        },
        component: function () {
          return import('@/views/subviews/evaluation/evaluation.vue')
        }
      },
      {
        path: '/evaluation/detail',
        name: '/evaluation/detail',
        meta: {
          title: '',
          label: '测评'
        },
        component: function () {
          return import('@/views/subviews/evaluation/evaluation_detail.vue')
        }
      },
      { // 正常测评结果
        path: '/evaluation/result',
        name: '/evaluation/result',
        meta: {
          title: '',
          label: '测评结果'
        },
        component: function () {
          return import('@/views/subviews/evaluation/evaluation_result.vue')
        }
      },
      { // 文字测评结果
        path: '/evaluation/result_text',
        name: '/evaluation/result_text',
        meta: {
          title: '',
          label: '测评结果'
        },
        component: function () {
          return import('@/views/subviews/evaluation/evaluation_result_text.vue')
        }
      },

      /**
       * @试题
       */
      {
        path: '/exam',
        name: '/exam',
        meta: {
          title: '试题',
          label: '试题'
        },
        component: function () {
          return import('@/views/subviews/exam/exam.vue')
        }
      },

      /**
       * @课程
       */
      {
        path: '/course',
        name: '/course',
        meta: {
          title: '课程分类',
          label: '课程分类'
        },
        component: function () {
          return import('@/views/subviews/course/course.vue')
        },
        redirect: '/course/list',
        children: [
          {
            path: '/course/list',
            name: '/course/list',
            meta: {
              title: '课程分类',
              label: ''
            },
            component: function () {
              return import('@/views/subviews/course/course_list.vue')
            }
          },
          {
            path: '/course/detail',
            name: '/course/detail',
            meta: {
              title: '课程详情',
              label: ''
            },
            component: function () {
              return import('@/views/subviews/course/course_detail.vue')
            }
          },
        ]
      },

      /**
       * @讲堂
       */
      {
        path: '/lecture',
        name: '/lecture',
        meta: {
          title: '升学讲堂',
          label: '升学讲堂'
        },
        component: function () {
          return import('@/views/subviews/lecture/lecture.vue')
        },
        redirect: '/lecture/list',
        children: [
          {
            path: '/lecture/list',
            name: '/lecture/list',
            meta: {
              title: '',
              label: '升学讲堂'
            },
            component: function () {
              return import('@/views/subviews/lecture/lecture_list.vue')
            }
          },
          {
            path: '/lecture/detail',
            name: '/lecture/detail',
            meta: {
              title: '志愿讲堂',
              label: '讲堂详情'
            },
            component: function () {
              return import('@/views/subviews/lecture/lecture_detail.vue')
            }
          }
        ]
      },

      /**
       * @百科
       */
      {
        path: '/encyclopedia',
        name: '/encyclopedia',
        meta: {
          title: '百科',
          label: '百科'
        },
        component: function () {
          return import('@/views/subviews/encyclopedia/encyclopedia.vue')
        }
      },


      /**
       * @查询列表
       */
      {
        path: '/search/list',
        name: '/search/list',
        meta: {
          title: '搜索',
          label: '搜索'
        },
        component: function () {
          return import('@/views/subviews/searchList/searchList.vue')
        }
      },


      /**
       * @服务
       */
      {
        path: '/service',
        name: '/service',
        meta: {
          title: '服务',
          label: '服务'
        },
        component: function () {
          return import('@/views/subviews/service/service.vue')
        },
        redirect: '/service/list',
        children: [
          {
            path: '/service/list',
            name: '/service/list',
            meta: {
              title: '',
              label: '服务'
            },
            component: function () {
              return import('@/views/subviews/service/service_list.vue')
            },
          },
          {
            path: '/service/detail',
            name: '/service/detail',
            meta: {
              title: '服务详情',
              label: '服务详情'
            },
            component: function () {
              return import('@/views/subviews/service/service_detail.vue')
            },
          },
          {
            path: '/service/alipay',
            name: '/service/alipay',
            meta: {
              title: '支付宝支付',
              label: '支付宝支付'
            },
            component: function () {
              return import('@/views/subviews/service/service_alipay.vue')
            },
          }
        ]
      },

      /**
       * @协议
       */
      {
        path: '/protocol',
        name: '/protocol',
        meta: {
          title: '协议/条款',
          label: '协议/条款'
        },
        component: function () {
          return import('@/views/subviews/user/user_protocol.vue')
        }
      },

      /**
       * @帮助
       */
      {
        path: '/help',
        name: '/help',
        meta: {
          title: '帮助中心',
          label: '帮助中心'
        },
        component: function () {
          return import('@/views/subviews/help/help.vue')
        }
      },


      /**
       * @个人中心
       */
      {
        path: '/user',
        name: '/user',
        meta: {
          title: '个人中心',
          label: '个人中心'
        },
        component: function () {
          return import('@/views/subviews/user/user.vue')
        },
        redirect: '/user/info',
        children: [
          {
            path: '/user/info',
            name: '/user/info',
            meta: {
              title: '基础信息',
              label: '基础信息'
            },
            component: function () {
              return import('@/views/subviews/user/user_info.vue')
            },
          },
          {
            path: '/user/application',
            name: '/user/application',
            meta: {
              title: '应用广场',
              label: '应用广场'
            },
            component: function () {
              return import('@/views/subviews/user/user_application.vue')
            },
          },
          {
            path: '/user/fillCollegeTable',
            name: '/user/fillCollegeTable',
            meta: {
              title: '志愿表',
              label: '志愿表'
            },
            component: function () {
              return import('@/views/subviews/user/user_fillCollegeTable.vue')
            },
          },
          {
            path: '/user/subjectScheme',
            name: '/user/subjectScheme',
            meta: {
              title: '选科方案',
              label: '选科方案'
            },
            component: function () {
              return import('@/views/subviews/user/user_subjectScheme.vue')
            },
          },
          {
            path: '/user/evaluation',
            name: '/user/evaluation',
            meta: {
              title: '测评报告',
              label: '测评报告'
            },
            component: function () {
              return import('@/views/subviews/user/user_evaluation.vue')
            },
          },
          {
            path: '/user/collect',
            name: '/user/collect',
            meta: {
              title: '关注/收藏',
              label: '关注/收藏'
            },
            component: function () {
              return import('@/views/subviews/user/user_collect.vue')
            },
          },
          {
            path: '/user/courseRecord',
            name: '/user/courseRecord',
            meta: {
              title: '课程观看记录',
              label: '课程观看记录'
            },
            component: function () {
              return import('@/views/subviews/user/user_courseRecord.vue')
            },
          },
          {
            path: '/user/order',
            name: '/user/order',
            meta: {
              title: '订单',
              label: '订单'
            },
            component: function () {
              return import('@/views/subviews/user/user_order.vue')
            },
          },
        ]
      },

      /**
       * @档案袋
       */
      {
        path: '/archives',
        name: '/archives',
        meta: {
          title: '档案袋',
          label: '档案袋'
        },
        component: function () {
          return import('@/views/subviews/archives/archives.vue')
        },
        redirect: '/archives/index',
        children: [
          {
            path: '/archives/index',
            name: '/archives/index',
            meta: {
              title: '志愿填报提示',
              label: '志愿填报提示'
            },
            component: function () {
              return import('@/views/subviews/archives/archives_index.vue')
            },
          },
          {
            path: '/archives/verify',
            name: '/archives/verify',
            meta: {
              title: '需求完整性检测',
              label: '需求完整性检测'
            },
            component: function () {
              return import('@/views/subviews/archives/archives_verify.vue')
            },
          },

          /**
           * @基础信息
           */
          {
            path: '/archives/info',
            name: '/archives/info',
            meta: {
              title: '高考基本信息',
              label: '高考基本信息'
            },
            component: function () {
              return import('@/views/subviews/archives/archives_info/archives_info.vue')
            },
          },
          {
            path: '/archives/info_intention',
            name: '/archives/info_intention',
            meta: {
              title: '基本意向设定',
              label: '基本意向设定'
            },
            component: function () {
              return import('@/views/subviews/archives/archives_info/archives_info_intention.vue')
            },
          },
          {
            path: '/archives/info_type',
            name: '/archives/info_type',
            meta: {
              title: '特殊类型报考',
              label: '特殊类型报考'
            },
            component: function () {
              return import('@/views/subviews/archives/archives_info/archives_info_type.vue')
            },
          },
          {
            path: '/archives/info_examine',
            name: '/archives/info_examine',
            meta: {
              title: '体检信息',
              label: '体检信息'
            },
            component: function () {
              return import('@/views/subviews/archives/archives_info/archives_info_examine.vue')
            },
          },

          /**
           * @志愿权重考虑
           */
          {
            path: '/archives/weight',
            name: '/archives/weight',
            meta: {
              title: '志愿权重考虑',
              label: '志愿权重考虑'
            },
            component: function () {
              return import('@/views/subviews/archives/archives_weight/archives_weight.vue')
            },
          },
          {
            path: '/archives/weight_interest',
            name: '/archives/weight_interest',
            meta: {
              title: '学科能力',
              label: '学科能力'
            },
            component: function () {
              return import('@/views/subviews/archives/archives_weight/archives_weight_interest.vue')
            },
          },
          {
            path: '/archives/weight_subject',
            name: '/archives/weight_subject',
            meta: {
              title: '学科兴趣',
              label: '学科兴趣'
            },
            component: function () {
              return import('@/views/subviews/archives/archives_weight/archives_weight_subject.vue')
            },
          },

          /**
           * @偏好
           */
          {
            path: '/archives/favor_major',
            name: '/archives/favor_major',
            meta: {
              title: '专业偏好',
              label: '专业偏好'
            },
            component: function () {
              return import('@/views/subviews/archives/archives_favor/archives_favor_major.vue')
            },
          },
          {
            path: '/archives/favor_school',
            name: '/archives/favor_school',
            meta: {
              title: '院校偏好',
              label: '院校偏好'
            },
            component: function () {
              return import('@/views/subviews/archives/archives_favor/archives_favor_school.vue')
            },
          },
          {
            path: '/archives/favor_city',
            name: '/archives/favor_city',
            meta: {
              title: '城市偏好',
              label: '城市偏好'
            },
            component: function () {
              return import('@/views/subviews/archives/archives_favor/archives_favor_city.vue')
            },
          },

          /**
           * @成绩册
           */
          {
            path: '/archives/grade',
            name: '/archives/grade',
            meta: {
              title: '成绩册',
              label: '成绩册'
            },
            component: function () {
              return import('@/views/subviews/archives/archives_grade.vue')
            },
          },
        ]
      },

      /**
       * @专家服务
       */
      {
        path: '/expertService',
        name: '/expertService',
        meta: {
          title: '专家服务',
          label: '专家服务'
        },
        component: function () {
          return import('@/views/subviews/expertService/expertService.vue')
        }
      },

      /**
       * @跳转中间件
       */
      {
        path: '/middleware',
        name: '/middleware',
        meta: {
          title: '',
          label: ''
        },
        component: function () {
          return import('@/views/subviews/middleware/middleware.vue')
        }
      },
    ]
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

// 权限判断
import routerVerify from '@/global/js/comm/routerVerify';
import config from '@/api/config.js';
import global from '@/global/js/global';
import store from '@/store'

// 全局导航守卫
router.beforeEach(async (to, from, next) => {
  // 网站路径名称
  if (to.meta.label) {
    document.title = to.meta.label + ` - ${config.brand_name}`
  } else {
    document.title = config.brand_name // 默认的title
  }

  // 访问路径不属于当前系统，则打开首页
  if (to.matched.length == 0) return next('/');
  
  // 判断权限 非首页
  if (to.path !== "/" && to.path !== '/home' && localStorage.getItem('token')) {
    const userInfo = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : {}
    if (typeof userInfo !== 'object' || Object.keys(userInfo).length == 0) {
      try {
        const res = await global.getUserInfo();

        if (res.data?.code != 200) return next('/');
      } catch (error) {
        return next('/');
      }
    }
    
    // 校验vip权限和登录权限
    if (routerVerify.verify(to.path) || routerVerify.verifyLogin(to.path)) return next(from.fullPath);
  }

  return next()
});

export default router
