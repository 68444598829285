
import gaokaoPerms from './directives/gaokaoPerms'

const modules = {
    gaokaoPerms
}

export default {
    // 自定义指令
    install(app) {
        Object.keys(modules).forEach((key) => {
            app.directive(key, modules[key])
        })
    }
}
