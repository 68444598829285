import { createStore } from 'vuex'
import router from '@/router';
import routerVerify from '@/global/js/comm/routerVerify';

export default createStore({
  state: {
    userInfo: {
      token: '',
      info: {}
    },

    // 网站信息
    officialInfo: {},
    // 登录状态
    loginStatus: {
      status: false,
      index: 0
    },
    // 省份弹窗状态
    provincePopupStatus: false,

    // vip弹窗
    vipStatus: 'none',

    // 搜索记录
    search: {
      search_introduction: [], //招生简章
      lecture: [], // 讲堂
    },

    // 高考资讯-历史记录
    search_history: [],

    // 大学搜索记录
    search_school: [],

    // 试题搜索记录
    exam_history: [],

    // 智能选科省份
    subjectProvince: {},
    // 省份数据
    province: {},

    // 浮动状态
    floatingShow: true,

    // 用户弹窗目录
    userIndex: '',

    // 用户选科
    userSubject: {
      section_id: 3,
      selected_subjects: []
    },
  },
  getters: {
    // 判断用户高考状态 nonGK: 非高考期间  nonYearGK: 高考期间非本年考生  isGK: 高考期间本年考生
    is_userGaokao(state) {
      let status = 'nonGK';
      const { ex_time, gaokao_year } = state?.userInfo?.info // 用户信息

      if (Array.isArray(ex_time) && ex_time.length == 2) {
        const getDate = (times) => new Date(times).getTime(); // 时间戳
        const getYear = (times) => new Date(times).getFullYear(); // 年份
        let now_date = getDate(new Date()); // 当前时间

        // 判断为高考期间
        // 当前时间大于等于最小高考时间，并小于最大高考时间
        if (now_date >= getDate(ex_time[0]) && now_date < getDate(ex_time[1])) {
          if (gaokao_year == getYear(ex_time[0])) {
            // 本年考生
            status = 'isGK'
          } else {
            // 非本年考生
            status = 'nonYearGK'
          }
        }
      }
      
      return status
    },

    is_userGaokao_verify(state) {
      return [ // 非高考年份限制的功能
        '/fillCollege', // 志愿
        '/fillCollege/index', // 志愿
        '/fillCollege/list', // 志愿列表
        '/fillCollege/result', // 志愿排序
        '/fillCollege/report', // 志愿报告
        '/archives/index', // 档案袋
        '/expertService', // 专家服务
      ]
    }
  },
  mutations: {
    // 登录弹窗状态
    addloginStatus(state, playod) {
      state.loginStatus = playod;
    },

    // 网站信息
    addOfficialInfo(state, playod) {
      state.officialInfo = playod;
    },

    // 高考资讯-搜索
    addSearchHistory(state, playod) {
      state.search_history = [];
      if (playod) state.search_history = JSON.parse(playod);
    },

    // 获取省份
    addProvince(state, playod) {
      state.province = playod;
    },

    // 获取个人信息
    addUserInfo(state, playod) {
      // 全部赋值
      if (playod.type == 'all') state.userInfo = playod.data;

      // 单个赋值
      if (playod.type == 'token') state.userInfo.token = playod.data;
      if (playod.type == 'info') state.userInfo.info = playod.data;
    },

    // 修改浮动展示
    editFloatingShow(state, playod) {
      state.floatingShow = playod
    },

    // 用户弹窗目录
    editUserIndex(state, playod) {
      state.userIndex = playod;
    },

    // 大学-搜索
    addSearchSchool(state, playod) {
      state.search_school = [];
      if (playod) state.search_school = JSON.parse(playod);
    },

    // 招生简介-搜索
    addSearchIntroduction(state, playod) {
      state.search_introduction = [];
      if (playod) state.search_introduction = JSON.parse(playod);
    },

    // 试题-搜索记录
    addExam(state, playod) {
      state.exam_history = [];
      if (playod) state.exam_history = JSON.parse(playod);
    },

    // 搜索记录
    addSearch(state, playod) {
      let key = playod.key ? playod.key : '';
      state.search[key] = [];
      if (playod) state.search[key] = JSON.parse(playod.data);
    },

    // 用户选科
    addUserSubject(state, playod) {
      state.userSubject = playod;
    },

    // vip弹窗
    openVipStatus(state, playod) {
      state.vipStatus = playod ? playod : 'none';
    },

    // 省份弹窗
    openProvincePopup(state, playod) {
      state.provincePopupStatus = playod ? playod : false;
    },
  },
  actions: {
    // 登录弹窗状态
    addloginStatus(content, playod) {
      content.commit('addloginStatus', playod);
    },

    // 网站信息
    addOfficialInfo(content, playod) {
      content.commit('addOfficialInfo', playod);
    },

    // 高考资讯-搜索
    addSearchHistory(content, playod) {
      content.commit('addSearchHistory', playod);
    },

    // 获取省份
    addProvince(content, playod) {
      content.commit('addProvince', playod);
    },

    // 获取个人信息
    addUserInfo(content, playod) {
      content.commit('addUserInfo', playod);
    },

    // 大学-搜索
    addSearchSchool(content, playod) {
      content.commit('addSearchSchool', playod);
    },

    // 招生简介-搜索
    addSearchIntroduction(content, playod) {
      content.commit('addSearchIntroduction', playod);
    },

    // 试题-搜索记录
    addExam(content, playod) {
      content.commit('addExam', playod);
    },

    // 搜索记录
    addSearch(content, playod) {
      content.commit('addSearch', playod)
    },

    // 获取用户选科数据
    addUserSubject(content, playod) {
      content.commit('addUserSubject', playod)
    },
  },
  modules: {
  }
})
