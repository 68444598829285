/**
 * @权限验证
 */
import storage from '@/global/js/comm/storage.js';
import global from '@/global/js/global.js';
import store from '@/store/index.js';
import router from '@/router';

class verify {
    // 路由权限列表
    login_verify = [ // 登录权限
        '/dataQuery/school/detail_matriculateLine', // 院校录取分数线
        '/dataQuery/school/detail_enrollmentPlan', // 院校招生计划
        '/dataQuery/major/detail_second?tab=2', // 专业开设院校
        '/dataQuery/job/detail', // 职业库
        '/dataQuery/job/detailClass', // 职业库-类别
        '/dataQuery/job/detailProfession', // 职业库-岗位
        '/dataQuery/rank', // 位次查询
        '/dataQuery/score', // 分数线
        '/evaluation/detail', // 测评详情
        '/evaluation/result', // 测评报告-图文
        '/evaluation/result_text', // 测评报告-文字
        '/user/evaluation', // 测评报告-列表
        '/user/subjectScheme', // 选科方案
        '/subjectSelection/query', // 选科科目查询
        '/subjectSelection/intelligent', // 智能选科
        '/subjectSelection/autonomy', // 自主选科
        '/fillCollege', // 志愿
        '/fillCollege/index', // 志愿入口
        '/fillCollege/list', // 志愿列表
        '/fillCollege/result', // 志愿排序
        '/fillCollege/report', // 志愿报告
        '/service/detail', // 服务卡详情
        '/service/alipay', // 支付宝支付
        '/exam', // 试题
        '/course', // 课程
        '/course/list', // 课程-列表
        '/course/detail', // 课程-详情
        '/lecture/list', // 讲堂
        '/archives/index', // 档案袋
        '/expertService', // 专家服务
    ]
    vip_verify = [] // vip权限

    // 存储路由权限
    set(value) {
        this.vip_verify = value;
    }

    // 获取路由权限
    get() {
        return this.vip_verify;
    }

    // 处理参数
    handle(list, query = { url: '', tab: '' }, type = 'none') {
        // 列表空时，则返回
        if (!list || list.length == 0) return false;

        // 状态
        let status = false;

        if (type == 'none') {
            // 无参数处理
            // 判断路由是否有权限，没有权限则返回true
            status = list.findIndex(el => el === query.url) !== -1 ? true : false;
        } else {
            // 参数处理
            // 获取当前路由是否存在权限数组中
            let fil = list.filter(el => el.indexOf(query.url) !== -1);
            // 存在权限数组
            if (fil.length > 0) {
                // 解析参数
                const verifyQuery = this.queryURLParams(fil[0]);
                // 参数与传参一致
                if (verifyQuery?.tab && verifyQuery?.tab == query?.tab) status = true;
            }
        }

        return status;
    }

    // 路由权限
    verify(value, is_popup = true) {
        // 用户权限
        let status = this.handle(this.vip_verify, { url: value });
        let popupType = '';

        // 高考权限判断
        if (!status && store.getters.is_userGaokao == 'nonYearGK') {
            status = this.handle(store.getters.is_userGaokao_verify, { url: value })
            popupType = 'nonYearGK';
        }

        // 页面响应弹窗提示
        status && is_popup && this.onPopup(popupType);

        return status;
    }

    // 路由权限（参数校验）
    verifyData(value, tab, is_popup = true) {
        // 判断
        let status = this.handle(this.vip_verify, { url: value, tab: tab }, 'data');
        let popupType = ''

        if (!status && store.getters.is_userGaokao == 'nonYearGK') {
            status = this.handle(store.getters.is_userGaokao_verify, { url: value, tab: tab }, 'data');
            popupType = 'nonYearGK'
        }

        // 页面响应弹窗提示
        status && is_popup && this.onPopup(popupType);

        return status;
    }

    // 路由权限 需登录
    verifyLogin(value) {
        if (storage.get('token')) return false;

        const status = this.handle(this.login_verify, { url: value });
        status && global.loginStatus();

        return status;
    }

    // 路由权限（参数校验）
    verifyLoginData(value, tab) {
        if (storage.get('token')) return false;

        // 判断
        const status = this.handle(this.login_verify, { url: value, tab: tab }, 'data');
        // 页面响应弹窗提示
        status && global.loginStatus();

        return status;
    }

    // VIP弹窗响应权限
    onPopup(types) {
        // 获取用户信息
        const userInfo = typeof storage.get('userInfo') === 'string' && storage.get('userInfo') ? JSON.parse(storage.get('userInfo')) : {};
        //  权限列表 is_vip == 1为vip，反之非vip
        store.commit('openVipStatus', types ? types : userInfo.is_vip == 1 ? 'upVip' : 'openVip')
    }

    // 解析路由参数
    queryURLParams(url) {
        let obj = {}
        let reg = /([^?=&]+)=([^?=&]+)/g
        url.replace(reg, function () {
            obj[arguments[1]] = arguments[2]
        })
        return obj
    }
}

const routerVerify = new verify();

export default routerVerify;