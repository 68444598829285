/**
 * @CommonLocalStorage 全局localStorage操作封装
 * @param { String, any } set 存储
 * @param { String } get 获取
 * @param { String } del 删除
 * @param clear 清除
 */

import bus from '@/global/js/comm/bus';

class CommonLocalStorage {
    #storage = null
    bus = bus
    constructor() {
        this.#storage = window.localStorage;
    }
    set(key, value) {
        // 执行监听的操作
        this.bus.emit('storageSet', {key: key, value: value});
        return this.#storage.setItem(key, typeof value === 'string' ? value : JSON.stringify(value));
    }
    get(key) {
        // 执行监听的操作
        this.bus.emit('storageGet', {key: key});
        return this.#storage.getItem(key);
    }
    del(key) {
        // 执行监听的操作
        this.bus.emit('storageDel', {key: key});
        return this.#storage.removeItem(key);
    }
    clear() {
        // 执行监听的操作
        this.bus.emit('storageClear');
        this.#storage.clear();
    }
}

const commonStorage = new CommonLocalStorage();

export default commonStorage
