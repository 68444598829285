import { createApp } from 'vue';
import App from './App.vue';

// 自定义主题色
import './global/scss/element.scss'

const app = createApp(App);

// 全局事件
import globalJS from './global/js/global';
app.config.globalProperties.$globalJS = globalJS;
// 中间件
import bus from './global/js/comm/bus';
app.config.globalProperties.$bus = bus;
// 请求
import request from './api/axios';
app.config.globalProperties.$request = request;
// 浏览器缓存
import storage from './global/js/comm/storage';
app.config.globalProperties.$zgyStorage = storage;
// 权限
import routerVerify from './global/js/comm/routerVerify';
app.config.globalProperties.$routerVerify = routerVerify;
// 配置信息
import config from './api/config';
app.config.globalProperties.$config = config;

// element-plus
import ElementPlus from 'element-plus';
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
import zhCn from 'element-plus/dist/locale/zh-cn.mjs';
app.use(ElementPlus, {
  locale: zhCn,
})

// 自定义指令或插件
import install from './global/install'
app.use(install)

// 状态管理
import store from './store';
app.use(store)

// 路由
import router from './router';
app.use(router)

app.mount('#app')
